import { useEffect, useState } from 'react';

import { gql } from '@apollo/client';
import { useRouter } from 'next/router';
import { useLazyQuery } from 'utils/hooks/useLazyQuery';

const GetFlowTitleQuery = gql`
  query getUserFlowTitle($url: String!, $projectId: String!) {
    userFlow {
      getUserFlowByUrl(projectId: $projectId, url: $url) {
        id
        title
      }
    }
  }
`;

export function useCurrentFlowTitle() {
  const { moduleUrl } = useRouter().query;

  const [userFlow, setUserFlow] = useState(undefined);
  const [getData, { called: flowCalled }] = useLazyQuery(GetFlowTitleQuery, {
    onCompleted: (data) => {
      const {
        userFlow: { getUserFlowByUrl },
      } = data;
      setUserFlow(getUserFlowByUrl);
    },
  });

  useEffect(() => {
    if (moduleUrl && !flowCalled) {
      getData({
        variables: { url: moduleUrl, projectId: process.env.REACT_APP_PROJECT_ID },
      });
    }
  }, [moduleUrl]);

  return { userFlow };
}
