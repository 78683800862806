import { useEffect } from 'react';

import { gql } from '@apollo/client';
import { useRouter } from 'next/router';
import { useLazyQuery } from 'utils/hooks/useLazyQuery';

const GetPageTitleQuery = gql`
  query GetPageTitleQuery($pageUrl: String!) {
    contentPage {
      get: getContentPagePerName(
        projectId: "${process.env.REACT_APP_PROJECT_ID}"
        contentPageUrl: $pageUrl
      ) {
        id
        title
      }
    }
  }
`;

export function useCurrentPageTitle() {
  const { pageUrl } = useRouter().query;

  const [getData, { data, called }] = useLazyQuery(GetPageTitleQuery);

  useEffect(() => {
    if (pageUrl && !called) {
      getData({
        variables: { pageUrl },
      });
    }
  }, [pageUrl]);

  return data?.contentPage?.get?.title;
}
