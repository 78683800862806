import { gql } from '@apollo/client';
import { useQuery } from 'utils/hooks/useQuery';
import { PERMISSIONS_MAP } from './permissionsMap';

const GET_GROUP_USERS_LIGHT = gql`
  query getGroupUsers($groupId: Int!, $permission: EMemberPermission) {
    group {
      getUsers(groupId: $groupId, permission: $permission) {
        userId
      }
    }
  }
`;

export function useGroupUsersQueryLight(roleType, groupId, client, onCompleted = undefined) {
  return useQuery(GET_GROUP_USERS_LIGHT, {
    client,
    skip: !Boolean(groupId),
    onCompleted(response) {
      if (!onCompleted) {
        return;
      }

      const {
        group: { getUsers: groupUsers },
      } = response;

      onCompleted(groupUsers);
    },
    variables: { groupId, permission: PERMISSIONS_MAP[roleType] },
  });
}
