import { useEffect } from 'react';

import { gql } from '@apollo/client';
import { useGroup, useUser } from 'contexts/Authorization/Context';
import { useLazyQuery } from 'utils/hooks/useLazyQuery';

const UserFlowFragment = gql`
  fragment UserFlowFragment on UserFlowType {
    id
    url
    title
    description
    hasAccess(groupId: $groupId)
    isVisible
    image {
      presignedUrl
    }
    userFlowSteps {
      id
      sort
      userStatus
      description
      url
      type
      percentageCompleted(unitId: $unitId)
      contentGroup {
        id
        sort
        type
        url
        title
        groupingContentGroup {
          id
          name
        }
      }
    }
  }
`;

const GetFlowsQuery = gql`
  query GetFlowsQuery($groupId:Int!) {
    userFlow {
      searchUserFlows(projectId: "${process.env.REACT_APP_PROJECT_ID}", isVisible:true) {
        ...UserFlowFragment
      }
    }
  }
  ${UserFlowFragment}
`;

const GetFlowsWithUnitQuery = gql`
query GetFlowsWithUnitQuery($unitId: Int!,$groupId:Int!) {
  userFlow {
    searchUserFlows(projectId: "${process.env.REACT_APP_PROJECT_ID}", isVisible:true) {
      ...UserFlowFragment
    }
  }
}
${UserFlowFragment}
`;
type EkPageType = 'Curriculum' | 'Description';

export function useUserFlowsStudentModule(pageTypeFilter?: EkPageType) {
  const user = useUser();
  const currentGroup = useGroup();
  const [getBasic, { data: basicData, ...othersBasic }] = useLazyQuery(GetFlowsQuery);
  const [getWithUnit, { data: withUnitData, ...othersUnit }] = useLazyQuery(GetFlowsWithUnitQuery);

  useEffect(() => {
    if (user?.unit?.id) {
      getWithUnit({ variables: { unitId: user.unit.id, groupId: currentGroup.id } });
    } else {
      getBasic({ variables: { groupId: currentGroup.id } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortedFlows = [...((basicData || withUnitData)?.userFlow?.searchUserFlows || [])]
    .sort((a, b) => a.id - b.id)
    .map((userFlow) => {
      return {
        ...userFlow,
        descriptionContentPages: (userFlow.descriptionContentGroup?.contentPages || []).filter(
          ({ contentPageType: { name: pageType } }) => pageType === (pageTypeFilter || ''),
        ),
      };
    });

  const data = user?.unit?.id ? withUnitData : basicData;
  const others = user?.unit?.id ? othersUnit : othersBasic;

  return { data, ...others, userFlows: sortedFlows };
}
