import { useState } from 'react';

import { setWithExpiry, getWithExpiry } from 'utils/functions/storeWithExpiry';

export function useStateWithExpiry(
  initalValue?: unknown,
  initalExpiry?: Date,
): [() => string | void, (newValue: string, expiry?: Date) => void] {
  const [value, setValue] = useState<string>(() => {
    return setWithExpiry(() => {}, initalValue, initalExpiry);
  });

  function setter(newValue: string, expiry?: Date) {
    setWithExpiry(setValue, newValue, expiry);
  }

  function getter() {
    return getWithExpiry(() => setValue(null), value);
  }

  return [getter, setter];
}
