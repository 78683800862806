import { parse } from 'querystring';
import { useEffect, useState } from 'react';

export function useQueryString() {
  const [params, setParams] = useState<{
    [key: string]: string | string[] | undefined | null;
  }>({});

  const search = typeof window !== 'undefined' && window.location.search.replace('?', '');

  useEffect(() => {
    setParams(parse(search));
  }, [search]);

  return params;
}
