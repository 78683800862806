import { gql } from '@apollo/client';
import { useQuery } from 'utils/hooks/useQuery';
import { PERMISSIONS_MAP } from './permissionsMap';

export const GET_GROUP_USERS_PAGINATED = gql`
  query (
    $groupId: Int!
    $permission: EMemberPermission!
    $first: Int!
    $customFields: [CustomFieldKeyPairValueInputType]
    $unitId: Int
    $filter: String
    $after: String
    $withUnit: Boolean
  ) {
    group {
      getUsersPaginated(
        after: $after
        groupId: $groupId
        filter: $filter
        withUnit: $withUnit
        permission: $permission
        first: $first
        customFields: $customFields
        unitId: $unitId
      ) {
        totalCount
        edges {
          cursor
          node {
            created
            group {
              id
            }
            id
            permision
            type
            assignedUnitDate
            unit {
              id
              name
            }
            user {
              credential {
                email
                phoneNumber
              }
              customFields {
                customFieldId
                id
                ... on CustomFieldResponseNumberGraphType {
                  number
                }
              }
              id
              firstname
              lastname
            }
          }
        }
      }
    }
  }
`;

export function useGroupUsersPaginatedQuery(roleType, groupId, client, first = 10, onCompleted = undefined) {
  return useQuery(GET_GROUP_USERS_PAGINATED, {
    client,
    skip: !groupId,
    onCompleted(response) {
      if (!onCompleted) {
        return;
      }

      const {
        group: {
          getUsersPaginated: { edges },
        },
      } = response;

      const users = edges.map((r) => r.node);

      onCompleted(users);
    },
    variables: { groupId, permission: PERMISSIONS_MAP[roleType], first },
  });
}
