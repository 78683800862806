import { useEffect, useState } from 'react';

import { gql } from '@apollo/client';
import { useRouter } from 'next/router';
import { useLazyQuery } from 'utils/hooks/useLazyQuery';

const GetNextStep = gql`
  query getNextStep($userFlowStepId: Int!) {
    userFlowStep {
      nextStep(userFlowStepId: $userFlowStepId) {
        id
        url
        stepName
      }
    }
  }
`;

export function useGetNextStep() {
  const [userFlowStep, setUserFlowStep] = useState(undefined);

  const [callGetUserFlowStep] = useLazyQuery(GetNextStep, {
    fetchPolicy: 'cache-and-network',
    onCompleted(response) {
      const {
        userFlowStep: { nextStep },
      } = response;

      setUserFlowStep(nextStep);
    },
  });

  const performGetNextStep = (userFlowStepId: number) => {
    callGetUserFlowStep({
      variables: {
        userFlowStepId,
      },
    });
  };

  return { performGetNextStep, userFlowStep };
}
