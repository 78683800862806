import React from 'react';

import { Breadcrumbs } from 'components/Shared/UI/Breadcrumbs';

import { BaseLayout, BaseLayoutProps } from './Base';

export function Layout({ title, children, description, className, crumbs }: BaseLayoutProps) {
  return (
    <BaseLayout title={title} description={description} crumbs={crumbs} className={className}>
      <Breadcrumbs />
      {children}
    </BaseLayout>
  );
}
