import { gql } from '@apollo/client';
import { useQuery } from 'utils/hooks/useQuery';
import { PERMISSIONS_MAP } from './permissionsMap';

export const GET_GROUP_USERS = gql`
  query getGroupUsers($groupId: Int!, $permission: EMemberPermission) {
    group {
      getUsers(groupId: $groupId, permission: $permission) {
        created
        group {
          id
        }
        id
        permision
        type
        assignedUnitDate
        unit {
          id
          name
        }
        user {
          credential {
            email
            phoneNumber
          }
          customFields {
            customFieldId
            id
            ... on CustomFieldResponseNumberGraphType {
              number
            }
          }
          id
          firstname
          lastname
        }
      }
    }
  }
`;

export function useGroupUsersQuery(roleType, groupId, client, onCompleted = undefined) {
  return useQuery(GET_GROUP_USERS, {
    client,
    skip: !Boolean(groupId),
    onCompleted(response) {
      if (!onCompleted) {
        return;
      }

      const {
        group: { getUsers: groupUsers },
      } = response;

      onCompleted(groupUsers);
    },
    variables: { groupId, permission: PERMISSIONS_MAP[roleType] },
  });
}
