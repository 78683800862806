import { useEffect, useState, useRef } from 'react';

import { gql } from '@apollo/client';
import { Client } from 'graphql/client';
import { useRouter } from 'next/router';
import { useLazyQuery } from 'utils/hooks/useLazyQuery';

const GET_USER_FLOW_STEP_TEACHER_DASHBOARD = gql`
  query GetFlowQuery($projectId: String!, $url: String!, $userFlowStepUrl: String) {
    userFlow {
      getUserFlowByUrl(projectId: $projectId, url: $url) {
        id
        title
        url
        description
        descriptionContentGroup {
          id
          type
          contentPages(status: [PUBLISHED, UNPUBLISHED]) {
            asset {
              id: assetId
              alt
              presignedUrl
            }
            id
            sort
            title
            url
            contentPageType {
              id
              name
            }
          }
        }
        userFlowSteps(url: $userFlowStepUrl) {
          id
          stepName
          isComplete
          sort
          userStatus
          description
          url
          type
          contentGroup {
            id
            iconAsset {
              alt
              presignedUrl
            }
            groupingContentGroup {
              id
              name
              contentGroups(types: [PAGE]) {
                id
                type
                contentPages(status: [PUBLISHED, UNPUBLISHED]) {
                  id
                  sort
                  contentPageType {
                    id
                    name
                  }
                  url
                }
              }
            }
            sort
            type
            url
            title
          }
          lastViewedContentPage {
            id
            url
          }
        }
      }
    }
  }
`;

export function useGetUserFlowStepsForTeacher(mustBePerformed: boolean = true, includeStepUrl = true) {
  const {
    query: { moduleUrl, stepUrl },
  } = useRouter();

  const client = useRef(Client('ekardo')).current;
  const projectId = process.env.REACT_APP_PROJECT_ID;

  const [selectedUserFlow, setSelectedUserFlow] = useState(undefined);

  const [executeGetUserFlowQuery, { loading: isLoading }] = useLazyQuery(GET_USER_FLOW_STEP_TEACHER_DASHBOARD, {
    client,
    onCompleted: (data) => {
      const {
        userFlow: { getUserFlowByUrl: userFlow },
      } = data;

      setSelectedUserFlow(userFlow);
    },
  });

  useEffect(() => {
    if (!projectId && !selectedUserFlow) {
      return;
    }
    if (!moduleUrl) {
      return;
    }

    if (mustBePerformed)
      executeGetUserFlowQuery({
        variables: {
          projectId,
          url: moduleUrl,
          userFlowStepUrl: includeStepUrl ? stepUrl : null,
        },
      });
  }, [projectId, selectedUserFlow, moduleUrl]);

  return { selectedUserFlow, isLoading };
}
