import { useEffect, useState } from 'react';

import { gql } from '@apollo/client';
import { useLazyQuery } from 'utils/hooks/useLazyQuery';

const GET_CONTENT_PAGE_BY_URL = gql`
  query getPageByUrl($url: String!, $projectId: String!) {
    contentPage {
      getContentPagePerName(projectId: $projectId, incrementStats: true, contentPageUrl: $url) {
        id
        seoTitle
        seoDescription
      }
    }
  }
`;

const GET_CONTENT_PAGE = gql`
  query getContentPage($contentPageId: Int!) {
    contentPage {
      getContentPage(contentPageId: $contentPageId) {
        seoTitle
        seoDescription
      }
    }
  }
`;

export const useCurrentPageSeoContent = (pageId?: number, pageUrl?: string) => {
  const [pageSeoTitle, setPageSeoTitle] = useState('');
  const [pageSeoDescription, setPageSeoDescription] = useState('');

  const [getPageContentByUrl] = useLazyQuery(GET_CONTENT_PAGE_BY_URL, {
    onCompleted: (data) => {
      const {
        contentPage: {
          getContentPagePerName: { seoTitle, seoDescription },
        },
      } = data;

      setPageSeoTitle(seoTitle);
      setPageSeoDescription(seoDescription);
    },
  });

  const [getPageContent] = useLazyQuery(GET_CONTENT_PAGE, {
    onCompleted: (data) => {
      const {
        contentPage: {
          getContentPage: { seoTitle, seoDescription },
        },
      } = data;

      setPageSeoTitle(seoTitle);
      setPageSeoDescription(seoDescription);
    },
  });

  useEffect(() => {
    if (pageId) {
      getPageContent({ variables: { contentPageId: pageId } });
    } else if (pageUrl) {
      getPageContentByUrl({ variables: { url: pageUrl, projectId: process.env.REACT_APP_PROJECT_ID } });
    }
  }, [pageId, pageUrl]);

  return { pageSeoDescription, pageSeoTitle };
};
