import { useEffect, useState, useRef } from 'react';

import { gql } from '@apollo/client';
import { Client } from 'graphql/client';
import { useRouter } from 'next/router';
import { useLazyQuery } from 'utils/hooks/useLazyQuery';

const GetFlowTitleQuery = gql`
  query GetFlowTitleQuery($projectId: String!) {
    userFlow {
      getUserflowForProject(projectId: $projectId) {
        id
        title
        url
      }
    }
  }
`;

export function useCurrentFlowTitleForProject() {
  const {
    query: { moduleUrl },
  } = useRouter();

  const client = useRef(Client('ekardo')).current;
  const projectId = process.env.REACT_APP_PROJECT_ID;

  const [userFlowTitle, setUserFlowTitle] = useState(undefined);

  const [executeGetUserFlowQuery] = useLazyQuery(GetFlowTitleQuery, {
    client,
    onCompleted: (data) => {
      const {
        userFlow: { getUserflowForProject: userFlow },
      } = data;

      const currentUserFlow = userFlow.find((item) => item.url === moduleUrl);

      setUserFlowTitle(currentUserFlow.title);
    },
  });

  useEffect(() => {
    if (!projectId && !userFlowTitle) {
      return;
    }

    executeGetUserFlowQuery({
      variables: {
        projectId,
      },
    });
  }, [projectId, userFlowTitle]);

  return { userFlowTitle };
}
