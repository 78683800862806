interface IStoredInformation {
  sceneIndex: number;
  sceneUrl: string;
  slideIndex: number;
}

export const useCartoonNavigation = () => {
  const trySetLastViewSlide = (module: string, sceneIndex: number, slideIndex: number, sceneUrl: string) => {
    const storageKey = `last-viewed-slide-${module}`;
    const storedItem = localStorage.getItem(storageKey);
    if (!storedItem) {
      const item: IStoredInformation = { sceneIndex, slideIndex, sceneUrl };
      localStorage.setItem(storageKey, JSON.stringify(item));
      return;
    }

    let item: IStoredInformation = JSON.parse(storedItem);
    if (item.sceneIndex < sceneIndex || (item.sceneIndex == sceneIndex && item.slideIndex < slideIndex)) {
      const overridingItem: IStoredInformation = { sceneIndex, slideIndex, sceneUrl };
      localStorage.setItem(storageKey, JSON.stringify(overridingItem));
    }
  };

  const getLatestViewedSlide = (module: string): IStoredInformation | undefined => {
    const storageKey = `last-viewed-slide-${module}`;
    const storedItem = localStorage.getItem(storageKey);
    if (!storedItem) {
      return null;
    }

    let item: IStoredInformation = JSON.parse(storedItem);
    return item;
  };

  const hasNextNavigation = (module: string, sceneIndex: number, slideIndex: number): boolean => {
    const storageKey = `last-viewed-slide-${module}`;
    const storedItem = localStorage.getItem(storageKey);
    if (!storedItem) {
      return false;
    }

    let item: IStoredInformation = JSON.parse(storedItem);
    if (item.sceneIndex > sceneIndex || (item.sceneIndex == sceneIndex && item.slideIndex > slideIndex)) {
      return true;
    }
    return false;
  };

  return { hasNextNavigation, trySetLastViewSlide };
};
