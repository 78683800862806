import { useRef, useState } from 'react';
import { gql } from '@apollo/client';

import { useMutation } from 'utils/hooks/useMutation';
import { Client } from 'graphql/client';

const HAS_SEEN_SPOTLIGHT = gql`
  mutation hasSeenSpotlight($url: String!) {
    user {
      hasSeenSpotlight(url: $url)
    }
  }
`;

export function useHasSeenSpotlight() {
  const [hasSeenSpotlight, setHasSeenSpotlight] = useState(true);
  const client = useRef(Client('gelada'));

  const [executeHasSeenSpotlight, { called }] = useMutation(HAS_SEEN_SPOTLIGHT, {
    client: client.current,

    onCompleted(data) {
      const {
        user: { hasSeenSpotlight: value },
      } = data;

      setHasSeenSpotlight(value);
    },
  });

  const performHasSeenSpotlight = (url: string) => {
    executeHasSeenSpotlight({
      variables: {
        url,
      },
    });
  };

  return { hasSeenSpotlight, performHasSeenSpotlight };
}
