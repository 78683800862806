import { useLazyQuery } from 'utils/hooks/useLazyQuery';
import { useGroup } from 'contexts/Authorization/Context';
import gql from 'graphql-tag';
import { Client } from 'graphql/client';
import { useRef, useState } from 'react';
import { useGroupUsersQueryLight } from './useGroupUsersQueryLight';

const HasUnmarkedActivity = gql`
  query GetStepTrackingQuery($userIds: [Int]!) {
    userFlowStepTrack {
      hasUnmarkedTracking(userIds: $userIds)
    }
  }
`;

export function useHasUnmarkedActivity() {
  const [hasUnmarkedActivity, setHasUnmarkedActivity] = useState(undefined);
  const currentGroup = useGroup();
  const geladaClient = useRef(Client('gelada'));
  const { data: usersData } = useGroupUsersQueryLight('STUDENTS', currentGroup.id, geladaClient.current);
  const [executeHasUnmarkedActivity, { called }] = useLazyQuery(HasUnmarkedActivity, {
    onCompleted(data) {
      const {
        userFlowStepTrack: { hasUnmarkedTracking },
      } = data;

      setHasUnmarkedActivity(hasUnmarkedTracking);
    },
  });
  const userIds = (usersData?.group?.getUsers || []).map(({ userId }) => userId);
  if (userIds.length > 0 && !called) {
    executeHasUnmarkedActivity({
      variables: {
        userIds,
      },
    });
  }
  return { hasUnmarkedActivity };
}
