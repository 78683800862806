import { useEffect } from 'react';

import { gql } from '@apollo/client';
import { useLazyQuery } from 'utils/hooks/useLazyQuery';

const GetDescriptionImageQuery = gql`
    query GetDescriptionImageQuery($contentPageUrl: String!) {
    contentPage {
      getContentPagePerName(
        projectId: "${process.env.REACT_APP_PROJECT_ID}"
        contentPageUrl: $contentPageUrl
      ) {
        id
        asset {
          id: assetId
          alt
          s3Key
          contentType
          presignedUrl
        }
      }
    }
  }

`;

export function useDescriptionImage(url?: string) {
  const [getDescriptionImage, { data: descriptionData }] = useLazyQuery(GetDescriptionImageQuery);

  useEffect(() => {
    if (url) {
      getDescriptionImage({
        variables: {
          contentPageUrl: url,
        },
      });
    }
  }, [url]);

  return descriptionData?.contentPage?.getContentPagePerName?.asset;
}
