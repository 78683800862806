import { useEffect, useState, useRef } from 'react';

import { gql } from '@apollo/client';
import { Client } from 'graphql/client';
import { useRouter } from 'next/router';
import { useLazyQuery } from 'utils/hooks/useLazyQuery';

const GetFlowQuery = gql`
  query getUserFlowForCartoon($projectId: String!, $url: String!, $userFlowStepUrl: String) {
    userFlow {
      getUserFlowByUrl(projectId: $projectId, url: $url) {
        id
        title
        url
        description
        userFlowSteps(url: $userFlowStepUrl) {
          id
          stepName
          isComplete
          sort
          userStatus
          description
          url
          type
          contentGroup {
            id
            sort
            type
            url
            title
            contentPages(status: [PUBLISHED, UNPUBLISHED]) {
              id
              sort
              url
              hasBeenViewed
              contentPageChildren(status: [PUBLISHED, UNPUBLISHED]) {
                url
                title
                hasBeenViewed
              }
            }
          }
          lastViewedContentPage {
            id
            url
          }
        }
      }
    }
  }
`;

export function useCurrentUserFlowForCartoon() {
  const {
    query: { moduleUrl, stepUrl },
  } = useRouter();

  const client = useRef(Client('ekardo')).current;
  const projectId = process.env.REACT_APP_PROJECT_ID;

  const [selectedUserFlow, setSelectedUserFlow] = useState(undefined);

  const [executeGetUserFlowQuery, { loading: isLoading }] = useLazyQuery(GetFlowQuery, {
    client,
    onCompleted: (data) => {
      const {
        userFlow: { getUserFlowByUrl: userFlow },
      } = data;

      setSelectedUserFlow(userFlow);
    },
  });

  useEffect(() => {
    if (!projectId && !selectedUserFlow) {
      return;
    }
    if (!moduleUrl) {
      return;
    }

    executeGetUserFlowQuery({
      variables: {
        projectId,
        url: moduleUrl,
        userFlowStepUrl: stepUrl,
      },
    });
  }, [projectId, selectedUserFlow, moduleUrl]);

  return { selectedUserFlow, isLoading };
}
