import { useEffect, useState } from 'react';

import { gql } from '@apollo/client';
import { useRouter } from 'next/router';
import { useLazyQuery } from 'utils/hooks/useLazyQuery';

const GetStepTitleQuery = gql`
  query GetStepTitleQuery($stepUrl: String!, $userFlowId: Int!) {
    userFlowStep {
      get: getUserFlowStepByName(userFlowStepUrl: $stepUrl, userFlowId: $userFlowId) {
        id
        title: stepName
      }
    }
  }
`;

export function useCurrentStepTitle() {
  const [step, setStep] = useState(undefined);
  const [getData] = useLazyQuery(GetStepTitleQuery, {
    onCompleted: (data) => {
      const {
        userFlowStep: { get },
      } = data;
      setStep(get);
    },
  });

  const handleGetStepByName = ({ userFlowId, stepUrl }) => {
    getData({
      variables: { userFlowId, stepUrl },
    });
  };

  return { handleGetStepByName, step };
}
