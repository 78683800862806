import { useEffect, useState } from 'react';

import { gql } from '@apollo/client';
import { useRouter } from 'next/router';
import { useLazyQuery } from 'utils/hooks/useLazyQuery';

const GetUserFlowStepQuery = gql`
  query getUserFlowStepQuery($userFlowId: Int!, $userFlowStepUrl: String!) {
    userFlowStep {
      getUserFlowStepByName(userFlowId: $userFlowId, userFlowStepUrl: $userFlowStepUrl) {
        contentGroup {
          contentPages(status: [PUBLISHED, UNPUBLISHED]) {
            id
            sort
            url
          }
          groupingContentGroup {
            contentGroups {
              id
              type
            }
            id
            name
          }
          id
          sort
          title
          type
          url
        }
        description
        grouping {
          contentGroups {
            contentPages(status: [PUBLISHED, UNPUBLISHED]) {
              id
              sort
            }
          }
          id
        }
        id
        lastViewedContentPage {
          id
          sort
          url
        }
        sort
        stepName
        type
        url
        userStatus
      }
    }
  }
`;

export function useCurrentUserFlowStep() {
  const {
    query: { flowId: userFlowId, stepUrl: userFlowStepUrl },
  } = useRouter();

  const [userFlowStep, setUserFlowStep] = useState(undefined);

  const [callGetUserFlowStep] = useLazyQuery(GetUserFlowStepQuery, {
    fetchPolicy: 'cache-and-network',
    onCompleted(response) {
      const {
        userFlowStep: { getUserFlowStepByName },
      } = response;

      setUserFlowStep(getUserFlowStepByName);
    },
  });

  useEffect(() => {
    if (!(userFlowId && userFlowStepUrl)) {
      return;
    }

    callGetUserFlowStep({
      variables: {
        userFlowId,
        userFlowStepUrl,
      },
    });
  }, [userFlowId, userFlowStepUrl]);

  return userFlowStep;
}
