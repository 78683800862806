import { useEffect } from 'react';

import { useRouter } from 'next/router';

export function useRedirect(href: string, as?: string) {
  const { replace } = useRouter();

  useEffect(() => {
    replace(href, as);
  });
}
